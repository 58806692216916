import React from 'react';
import { ReactEChart } from './charts';


export default function AveragePriceOfDay() {
    const [housingData, setHousingData] = React.useState(false);
    const [isFetching, setIsFetching] = React.useState(true);
    let ymin = 0;
    let ymax = 100;
    if (housingData.prices) {
        ymin = Math.min(...housingData.prices);
        ymax = Math.max(...housingData.prices);
        const yrange = ymax - ymin;
        ymin = parseInt(ymin - yrange * 0.1);
        ymax = parseInt(ymax + yrange * 0.1);
    }
    React.useEffect(() => {
        if (isFetching) {
            fetchData(setHousingData);
            setIsFetching(false);
        }
    }, [isFetching]);
    let option = {
        title: {
            text: 'Price'
        },
        legend: {
            data: ['Average Price per Square Meter - Living Area'],
            right: '10%'
        },
        xAxis: {
            type: 'category',
            data: housingData.dates
        },
        yAxis: {
            type: 'value',
            min: ymin,
            max: ymax,
        },
        series: [
            {
                name: 'Average Price per Square Meter - Living Area',
                data: housingData.prices,
                type: 'line',
                smooth: true
            }
        ]
    };
    return (
        <ReactEChart option={option} />
    );
}



async function fetchData(setData) {
    const response = await fetch(
        '/data/price-of-date', {
        method: 'GET',
        mode: 'no-cors',
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json'
        }
    });
    const response_data = await response.text();
    let data = JSON.parse(response_data);
    setData(data);
}