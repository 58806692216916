import React from 'react';
import './App.css';
import AveragePriceOfDay from './averagePriceOfDay';
import OfferSellFrequencyChart from './offerSellFrequency'
import Navbar from './navBar'

import { createTheme } from '@mui/material/styles';
import { grey, orange } from '@mui/material/colors';
import { ThemeProvider } from '@emotion/react';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';



const theme = createTheme({
  palette: {
    primary: {
      main: grey[900],
    },
    secondary: {
      main: orange[500],
    },
  },
  typography: {
    body1: {
      color: 'orange',
    },
    body2: {
      color: 'orange',
    },
    string: {
      color: 'orange',
    },
    button: {
      color: 'orange',
    },
    caption: {
      color: 'orange',
    }
  },
});

function App() {
  return (
    <div>
      <Navbar />
      <Box sx={{ flexGrow: 1, mt: 4 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <AveragePriceOfDay />
          </Grid>
          <Grid item xs={12} lg={6}>
            <OfferSellFrequencyChart />
          </Grid>
        </Grid>
      </Box>
    </div >
  );
}


export default App;
