import React from 'react';
import { ReactEChart } from './charts';


export default function OfferSellFrequencyChart() {
    const [data, setData] = React.useState(false);
    const [isFetching, setIsFetching] = React.useState(true);
    let ymin = 0;
    let ymax = 50;
    if (data) {
        ymax = Math.max(...data.offer_frequency, ...data.sell_frequency);
        ymin = 0;
        ymax = parseInt(ymax * 1.1);
    }
    React.useEffect(() => {
        if (isFetching) {
            fetchData(setData);
            setIsFetching(false);
        }
    }, [isFetching]);
    let option = {
        title: {
            text: 'Trade Activity'
        },
        legend: {
            data: ['Sell Frequency', 'Buy Frequency'],
            right: '10%',
            orient: 'vertical'
        },
        xAxis: {
            type: 'category',
            data: data.dates
        },
        yAxis: {
            type: 'value',
            min: ymin,
            max: ymax,
        },
        series: [
            {
                name: 'Sell Frequency',
                data: data.offer_frequency,
                type: 'line',
                smooth: true
            },
            {
                name: 'Buy Frequency',
                data: data.sell_frequency,
                type: 'line',
                smooth: true
            }
        ]
    };
    return (
        <ReactEChart option={option} />
    );
}



async function fetchData(setData) {
    const response = await fetch(
        '/data/offer-sell-frequency', {
        method: 'GET',
        mode: 'no-cors',
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json'
        }
    });
    const response_data = await response.text();
    let data = JSON.parse(response_data);
    setData(data);
}